import { Component, OnInit } from '@angular/core';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  images = [
      {name: 'сертификат 1', id: 1, url: 'assets/image/sert/1.jpg'},
      {name: 'сертификат 2', id: 2, url: 'assets/image/sert/2.jpg'},
      {name: 'сертификат 3', id: 3, url: 'assets/image/sert/3.jpg'},
      ];
  picture = [];
  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }
  open(content) {
    this.modalService.open(content, { size: 'xl' });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
}
